import React from "react"
import ContentLoader from "react-content-loader"

export const ReviewPlaceholder = ({ loading, className = "", children }) => {
  return (
    <div className={className}>
      {loading ? (
        <ContentLoader speed={0.8} viewBox="0 0 380 70">
          {/* Only SVG shapes */}
          <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
          <rect x="80" y="5" rx="3" ry="3" width="50" height="10" />
          <rect x="150" y="5" rx="3" ry="3" width="230" height="10" />
          <rect x="80" y="30" rx="4" ry="4" width="300" height="13" />
          <rect x="80" y="45" rx="4" ry="4" width="300" height="13" />
        </ContentLoader>
      ) : (
        children
      )}
    </div>
  )
}

export const QuestionPlaceholder = ({ loading }) => {
  return (
    <div className={`${loading ? "" : "hidden"}`}>
      <ContentLoader viewBox="0 0 380 162">
        {/* Only SVG shapes */}
        <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
        <rect x="80" y="5" rx="3" ry="3" width="150" height="10" />
        <rect x="80" y="30" rx="4" ry="4" width="300" height="13" />
        <rect x="80" y="45" rx="4" ry="4" width="300" height="13" />

        <rect x="50" y="90" rx="5" ry="5" width="70" height="70" />
        <rect x="130" y="95" rx="3" ry="3" width="100" height="10" />
        <rect x="130" y="120" rx="4" ry="4" width="250" height="13" />
        <rect x="130" y="135" rx="4" ry="4" width="250" height="13" />
      </ContentLoader>
    </div>
  )
}

export const LineItemPlaceholder = ({ className, loading, children }) => (
  <div className={className}>
    {loading ? (
      <ContentLoader speed={0.8} viewBox="0 0 480 120">
        {/* Only SVG shapes */}
        <rect x="0" y="0" rx="5" ry="5" width="120" height="120" />
        <rect x="152" y="0" rx="3" ry="3" width="70" height="20" />
        <rect x="152" y="30" rx="4" ry="4" width="330" height="20" />
        <rect x="152" y="60" rx="4" ry="4" width="330" height="20" />
        <rect x="152" y="100" rx="4" ry="4" width="60" height="20" />
      </ContentLoader>
    ) : (
      children
    )}
  </div>
)

export const AccountDetailsPlaceholder = ({ loading }) =>
  loading ? (
    <ContentLoader speed={0.8} viewBox="0 0 480 165">
      {/* Only SVG shapes */}
      <rect x="0" y="0" rx="5" ry="5" width="200" height="20" />
      <rect x="0" y="25" rx="5" ry="5" width="250" height="20" />
      <rect x="0" y="55" rx="3" ry="3" width="480" height="15" />
      <rect x="0" y="75" rx="3" ry="3" width="480" height="15" />
      <rect x="0" y="95" rx="3" ry="3" width="480" height="15" />
      <rect x="0" y="120" rx="3" ry="3" width="480" height="20" />
      <rect x="0" y="145" rx="3" ry="3" width="480" height="20" />
    </ContentLoader>
  ) : null

export const OrderHistoryPlaceholder = ({ loading }) =>
  loading ? (
    <ContentLoader speed={0.8} viewBox="0 0 480 320">
      {/* Only SVG shapes */}
      <rect x="0" y="0" rx="5" ry="5" width="480" height="20" />
      <rect x="0" y="30" rx="3" ry="3" width="480" height="15" />
      <rect x="0" y="50" rx="3" ry="3" width="480" height="15" />
      <rect x="0" y="70" rx="3" ry="3" width="480" height="15" />
      <rect x="0" y="120" rx="3" ry="3" width="480" height="20" />
      <rect x="0" y="145" rx="3" ry="3" width="480" height="15" />
    </ContentLoader>
  ) : null

export const ReviewFilterPlaceholder = ({ loading }) => {
  return (
    <div className={`${loading ? "" : "hidden"}`}>
      <ContentLoader viewBox="0 0 380 70">
        {/* Only SVG shapes */}
        <rect x="0" y="0" rx="5" ry="5" width="70" height="33" />
        <rect x="0" y="40" rx="5" ry="5" width="70" height="10" />
        <rect x="0" y="55" rx="5" ry="5" width="70" height="10" />
        <rect x="130" y="5" rx="4" ry="4" width="250" height="15" />
        <rect x="130" y="25" rx="4" ry="4" width="250" height="15" />
        <rect x="130" y="45" rx="4" ry="4" width="250" height="13" />
      </ContentLoader>
    </div>
  )
}

export const ShopNowPlaceholder = ({ loading, className, children }) => {
  return (
    <div className={className}>
      {loading ? (
        <ContentLoader viewBox="0 0 380 80">
          {/* Only SVG shapes */}
          <rect x="0" y="0" rx="5" ry="5" width="80" height="80" />
          <rect x="100" y="15" rx="4" ry="4" width="250" height="15" />
          <rect x="100" y="40" rx="4" ry="4" width="80" height="25" />
        </ContentLoader>
      ) : (
        children
      )}
    </div>
  )
}

export const SubscriptionDetailPlaceholder = ({
  loading,
  className = "",
  children,
}) => {
  return (
    <div className={className}>
      {loading ? (
        <div className="flex flex-col lg:flex-row items-start gap-8">
          <ContentLoader viewBox="0 0 360 360">
            {/* Only SVG shapes */}
            <rect x="0" y="0" rx="5" ry="5" width="360" height="360" />
          </ContentLoader>

          <ContentLoader viewBox="0 0 380 480">
            {/* Only SVG shapes */}
            <rect x="0" y="0" rx="4" ry="4" width="200" height="25" />
            <rect x="0" y="50" rx="4" ry="4" width="380" height="25" />
            <rect x="0" y="85" rx="4" ry="4" width="80" height="25" />
            <rect x="0" y="135" rx="4" ry="4" width="180" height="200" />
            <rect x="200" y="135" rx="4" ry="4" width="180" height="200" />
            <rect x="0" y="350" rx="4" ry="4" width="380" height="25" />
          </ContentLoader>
        </div>
      ) : (
        children
      )}
    </div>
  )
}

export const SelectChoiceContentPlaceholder = ({ loading, children }) => {
  return (
    <>
      {loading ? (
        <ContentLoader speed={0.8} viewBox="0 0 240 102">
          {/* Only SVG shapes */}
          <rect x="0" y="0" rx="5" ry="5" width="240" height="16" />
          <rect x="0" y="24" rx="3" ry="3" width="240" height="16" />
          <rect x="0" y="48" rx="3" ry="3" width="240" height="16" />
          <rect x="0" y="78" rx="4" ry="4" width="84" height="24" />
        </ContentLoader>
      ) : (
        children
      )}
    </>
  )
}

export const PaymentDetailsPlaceholder = ({ loading, children }) => {
  return (
    <>
      {loading ? (
        <div className="max-w-[11.75rem] h-12">
          <ContentLoader speed={0.8} viewBox="0 0 188 40">
            {/* Only SVG shapes */}
            <rect x="0" y="0" rx="5" ry="5" width="188" height="17" />
            <rect x="0" y="23" rx="5" ry="5" width="40" height="17" />
          </ContentLoader>
        </div>
      ) : (
        children
      )}
    </>
  )
}
