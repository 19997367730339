import React, { FC, Fragment } from "react"
import { XIcon } from "@heroicons/react/solid"
import { Transition, Dialog } from "@headlessui/react"

export interface SelectChoiceDetailsProps {
  isVisible: boolean
  setIsVisible: any
  slotDetails: any
}

const SelectChoiceDetails: FC<SelectChoiceDetailsProps> = ({
  isVisible,
  setIsVisible,
  slotDetails,
}) => {
  const imgSrc = slotDetails?.images?.[0] ?? ""
  const title = slotDetails?.title ?? ""
  const description = slotDetails?.description ?? ""

  return (
    <Transition.Root show={isVisible} as={Fragment}>
      <Dialog
        as="div"
        className="overflow-y-auto fixed inset-0 z-10"
        onClose={setIsVisible}
      >
        <div className="flex justify-center items-end px-4 pt-4 pb-20 min-h-screen text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block relative align-bottom bg-white rounded-md px-6 py-8 text-left overflow-hidden shadow-xl transition-all w-full sm:my-8 sm:align-middle sm:max-w-[51.75rem] sm:p-8">
              <div className="flex justify-end">
                <Dialog.Title
                  as="h4"
                  className="text-2xl font-bold leading-6 sr-only text-idc-title"
                >
                  Coffee Details
                </Dialog.Title>
                <button
                  type="button"
                  className="text-gray-400 bg-white rounded-md hover:text-gray-600 focus:outline-none focus:text-gray-600"
                  onClick={() => setIsVisible(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="w-4 h-4 text-gray-400" />
                </button>
              </div>

              <div className="flex flex-col gap-6 my-8 lg:flex-row">
                <div className="relative h-[17rem] xl:h-[15rem] 2xl:h-[17rem] w-[17rem] xl:w-[15rem] 2xl:w-[17rem] aspect-square bg-gray-100 mb-6 mx-auto">
                  {imgSrc && (
                    <img
                      className="object-contain absolute w-full h-full"
                      src={imgSrc.src}
                      alt={imgSrc.altText}
                    />
                  )}
                </div>
                <div>
                  <p className="mb-2 text-2xl font-bold text-idc-title">
                    {title}
                  </p>
                  <p className="mb-8 text-idc-body">{description}</p>
                </div>
              </div>
              <div className="flex justify-end">
                <button
                  className="px-8 py-2 font-semibold rounded-full border-2 transition duration-300 ease-in-out border-idc-blue text-idc-blue hover:bg-idc-light-blue active:bg-idc-light-blue"
                  onClick={() => setIsVisible(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default SelectChoiceDetails
