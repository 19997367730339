import React from "react"

const Check = () => (
  <svg width="14" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.036.763a.9.9 0 0 1 0 1.273l-7.2 7.2a.9.9 0 0 1-1.273 0l-3.6-3.6a.9.9 0 1 1 1.273-1.273l2.963 2.964L11.763.763a.9.9 0 0 1 1.273 0Z"
      fill="currentColor"
    />
  </svg>
)

export default Check
