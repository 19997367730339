import React, { FC } from "react"
import { Link } from "gatsby"
import { ISubscriptionFeature } from "../CTA/index"
import { getResourceUrl } from "utils/strapi"

export interface TierCardProps {
  title?: string
  price: number
  coffeeSelectionQuantity: number
  features: ISubscriptionFeature[]
  mostPopular: boolean
  linkText?: string
  icon?: string
  groupId?: string
  showLinks?: boolean
}

const TierCard: FC<TierCardProps> = ({
  title = "Tier",
  price,
  coffeeSelectionQuantity,
  features,
  mostPopular,
  icon,
  linkText = "Select Coffees",
  groupId = "129925",
  showLinks = true,
}) => {
  return (
    <div
      className={`rounded-2xl inline-block p-8 relative bg-white opacity-95 shrink-0 ${
        mostPopular ? "border-2 border-idc-orange" : "border-idc-white"
      }`}
    >
      {mostPopular ? (
        <p className="uppercase text-white text-xs bg-idc-orange font-bold inline-block absolute top-0 m-0 -mt-4 ml-4 px-4 py-2 rounded-2xl left-0">
          Most Popular
        </p>
      ) : null}

      <div className="flex flex-row justify-center">
        <div
          className={`inline-block p-[2.1875rem] rounded-full opacity-100 ${
            mostPopular ? "bg-idc-light-red" : "bg-idc-light"
          }`}
        >
          <div className="w-[4.625rem] h-[4.625rem]">
            <img
              src={getResourceUrl(icon?.url)}
              alt={icon?.alternativeText}
              className="w-full h-full object-cover object-center"
            />
          </div>
        </div>
      </div>

      <h2
        className={`font-semibold text-xl mb-4 mt-2 m-0 ${
          mostPopular ? "text-idc-orange" : "text-idc-body"
        }`}
      >
        {title}
      </h2>
      <p className="font-extrabold text-5xl m-0">
        ${price}
        <span className="font-semibold text-xl"> /shipment</span>
      </p>
      <p className="font-normal text-base text-g my-4 m-0 text-idc-body">
        Choose from a selection of{" "}
        <strong className="text-black">
          {coffeeSelectionQuantity} coffees
        </strong>
      </p>
      {features.map((benefit) => (
        <div className="flex flex-row" key={benefit.id}>
          <div className="flex flex-col justify-center shrink-0 mr-3">
            <img src="/assets/icon/green-tick.svg" alt="" />
          </div>
          <p className="text-left text-base font-normal my-2 text-idc-body m-0">
            {benefit.text}
          </p>
        </div>
      ))}

      {showLinks && (
        <Link
          to="select-choices/"
          className={`text-white flex justify-center mt-12 rounded-full py-3 font-semibold text-base m-0 cursor-pointer transition duration-300 ease-in-out ${
            mostPopular
              ? "bg-idc-orange hover:bg-idc-blue hover:bg-opacity-75 active:bg-idc-blue"
              : "bg-idc-blue hover:bg-[#F47761] active:bg-idc-orange"
          }`}
          state={{
            groupId,
            tier: {
              title,
              price,
              coffeeSelectionQuantity,
              features,
              mostPopular,
              icon,
              linkText,
              groupId,
              showLinks: false,
            },
          }}
        >
          {linkText}
        </Link>
      )}
    </div>
  )
}

export default TierCard
