import React, { FC } from "react"
import PlusIcon from "assets/icons/plus"
import MinusIcon from "assets/icons/minus"

export interface NumericInputProps {
  inputId: string
  onIncrement: any
  onDecrement: any
  disabled: boolean
  value: number
}

const NumericInput: FC<NumericInputProps> = ({
  inputId = "",
  onIncrement,
  onDecrement,
  disabled,
  value,
  ...props
}) => {
  return (
    <div className="flex flex-nowrap">
      <button
        type="button"
        disabled={disabled}
        className={`w-10 h-10 border border-[#D4D4D8] rounded-l-full bg-[#F9F7F5] disabled:cursor-not-allowed disabled:opacity-50 ${
          value > 1
            ? "hover:border-[#F47761] active:border-idc-orange"
            : "opacity-50 cursor-not-allowed"
        } transition duration-300 ease-in-out`}
        aria-label="Decrement"
        onClick={onDecrement}
      >
        <MinusIcon className="align-middle mx-auto" />
      </button>

      <input
        disabled={disabled}
        style={{ borderColor: "#D4D4D8" }}
        type="numeric"
        id={inputId}
        className="w-10 h-10 border-t border-b text-center font-medium text-sm"
        value={value}
        {...props}
      />

      <button
        type="button"
        disabled={disabled}
        className="w-10 h-10 border border-[#D4D4D8] rounded-r-full bg-[#F9F7F5] disabled:cursor-not-allowed disabled:opacity-50 hover:border-[#F47761] transition duration-300 ease-in-out"
        aria-label="Increment"
        onClick={onIncrement}
      >
        <PlusIcon className="align-middle mx-auto" />
      </button>
    </div>
  )
}

export default NumericInput
