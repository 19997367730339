import React, { FC } from "react"
import NumericInput from "components/elements/NumericInput"
import { v4 as uuid } from "uuid"

export interface QuantitySelectProps {
  quantity: number
  setQuantity: any
  min: number
  controlActive?: boolean
}
const QuantitySelect: FC<QuantitySelectProps> = ({
  quantity,
  setQuantity,
  min = 1,
  controlActive = true,
}) => {
  const inputId = uuid()

  const handleQuantityChange = (value) => {
    if (value !== "" && Number(value) < min) {
      return
    }
    setQuantity(value)
  }

  function doIncrement() {
    handleQuantityChange(Number(quantity || 0) + 1)
  }

  function doDecrement() {
    handleQuantityChange(Number(quantity || 0) - 1)
  }

  return (
    <div className={controlActive ? "" : "opacity-50 pointer-events-none"}>
      <label
        htmlFor={inputId}
        className="text-idc-title block text-sm font-medium mb-1"
      >
        Quantity
      </label>
      <NumericInput
        inputId={inputId}
        value={quantity}
        disabled={!controlActive}
        aria-label="Quantity"
        onIncrement={doIncrement}
        onDecrement={doDecrement}
        onChange={(e) => handleQuantityChange(e.currentTarget.value)}
      />
    </div>
  )
}

export default QuantitySelect
